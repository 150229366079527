import React, { useState } from "react";
import { Img } from "../../components";

import { twMerge } from "tailwind-merge";
import { Marquee } from "../../components/Slidebar";
import { useMediaQuery } from "react-responsive";
import { useInView } from "react-intersection-observer";
import { AppFooter } from "components/Footer";

export const Partners = () => {
  return (
    <div className="flex flex-col w-full items-center overflow-hidden justify-center font-inter overflow-x-hidden space-y-4">
      <h2 class="reveal-up mt-[10%] text-2xl text-white max-md:text-xl">
        Friends
      </h2>

      <div
        className={twMerge(
          "flex flex-col ml-[5%] sm:ml-[0%] w-[1000px] items-center overflow-hidden justify-center font-inter overflow-x-hidden space-y-4"
        )}
      >
        <Marquee className="overflow-hidden" speed={0.7}>
          <div className="whitespace-nowrap select-none w-full flex flex-row space-x-12">
            <span className=" items-center"></span>

            <span className="w-40 flex flex-row items-center">
              <Img
                className="w-38 h-38"
                src={"/images/phantom.png"}
                alt="Magic Eden"
              />
            </span>

            <span className="w-64 flex flex-row items-center">
              <Img
                className="w-38 h-38"
                src={"/images/solanafnd.png"}
                alt="Magic Eden"
              />
            </span>

            <span className="w-64 flex flex-row items-center">
              <Img
                className="w-38 h-38"
                src={"/images/stakingfacilities.png"}
                alt="Magic Eden"
              />
            </span>

            <span className="w-64 flex flex-row items-center">
              <Img
                className="w-38 h-38"
                src={"/images/superteamgermany.png"}
                alt="SG"
              />
            </span>

            <span className="w-64 flex flex-row items-center mr-[10%]">
              <Img
                className="w-38 h-38"
                src={"/images/solflare.png"}
                alt="Magic Eden"
              />
            </span>
          </div>
        </Marquee>
      </div>
    </div>
  );
};

export const AboutPane = () => {
  const isDesktop = useMediaQuery({ maxWidth: 767 });

  return !isDesktop ? (
    <div></div>
  ) : (
    <div className="mt-[4%] grid gap-10 w-[100%] h-[2100px] px-6 xl:w-2/3 self-center"></div>
  );
};

export function FAQ() {
  return (
    <div>
      <section className="font-inter p-5 bg-black pb-12 pt-20 bg-black lg:pb-[90px] lg:pt-[120px]">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div className="mx-auto mb-[60px] max-w-[520px] text-center lg:mb-20">
                <span className="mb-2 block sm-text-xl text-lg text-orange font-semibold text-primary">
                  FAQ
                </span>
                <h2 className="mb-4 text-3xl font-bold text-white sm:text-2xl md:text-3xl lg:text-[40px] xl:text-[48px] px-4 sm:px-6">
                  Any Questions? Look Here
                </h2>
              </div>
            </div>
          </div>

          <div className="-mx-4 max-h-[800px] flex flex-col">
            <div className="w-full px-4 lg:w-1/2">
              <AccordionItem
                header="Who can join your talent pool?"
                text="Any active member of the Solana community, including developers, marketers, and designers, looking for new career opportunities within the ecosystem."
              />
              <AccordionItem
                header="How do you match me with a job?"
                text="After you join the talent pool, we carefully review your profile and get in touch with you to better understand your goals, requirements, and needs. This allows us to match you with job openings that align with your skills and aspirations, ensuring we create the best fit for you."
              />
            </div>
            <div className="w-full px-4 lg:w-1/2">
              <AccordionItem
                header="What if I don’t find a job right away?"
                text="If an immediate match isn’t available, don’t worry. We’ll keep your profile in our talent pool and notify you when new opportunities that align with your goals become available."
              />

              <AccordionItem
                header="Can I update my profile after joining the talent pool?"
                text="Yes, you can update your profile at any time. Just get in touch with us, and we’ll ensure that your latest skills and preferences are reflected in our matching process."
              />
              <AccordionItem
                header="How is my data protected?"
                text="We take your privacy seriously. Your personal information is kept secure and is only used for the purpose of matching you with relevant job opportunities. We do not share your data with any third party without your explicit consent."
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const AccordionItem = ({ header, text }) => {
  const [active, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!active);
  };
  return (
    <div className="mb-8 w-full rounded-lg bg-transparent text-white sm:p-8 lg:px-6 xl:px-8">
      <button
        className={`faq-btn flex w-full text-left`}
        onClick={() => handleToggle()}
      >
        <div className="mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-primary/5 text-primary dark:bg-white/5">
          <svg
            className={`fill-primary stroke-primary duration-200 ease-in-out ${
              active ? "rotate-180" : ""
            }`}
            width="17"
            color="white"
            height="10"
            viewBox="0 0 17 10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.28687 8.43257L7.28679 8.43265L7.29496 8.43985C7.62576 8.73124 8.02464 8.86001 8.41472 8.86001C8.83092 8.86001 9.22376 8.69083 9.53447 8.41713L9.53454 8.41721L9.54184 8.41052L15.7631 2.70784L15.7691 2.70231L15.7749 2.69659C16.0981 2.38028 16.1985 1.80579 15.7981 1.41393C15.4803 1.1028 14.9167 1.00854 14.5249 1.38489L8.41472 7.00806L2.29995 1.38063L2.29151 1.37286L2.28271 1.36548C1.93092 1.07036 1.38469 1.06804 1.03129 1.41393L1.01755 1.42738L1.00488 1.44184C0.69687 1.79355 0.695778 2.34549 1.0545 2.69659L1.05999 2.70196L1.06565 2.70717L7.28687 8.43257Z"
              fill="white"
              stroke=""
            />
          </svg>
        </div>

        <div className="w-full">
          <h4 className="mt-1 text-lg font-bold text-white">{header}</h4>
        </div>
      </button>

      <div
        className={`pl-[62px] duration-200 ease-in-out ${
          active ? "block" : "hidden"
        }`}
      >
        <p className="py-3 leading-relaxed text-body-color italic">{text}</p>
      </div>
    </div>
  );
};

export function Navigation() {
  return (
    <div>
      <section className="p-5 mt-[-3%] bg-black font-inter">
        <div className="max-w-screen-xl px-4 mx-auto overflow-hiddensm:px-6 lg:px-8">
          <nav className="flex justify-between sm:flex-col items-center">
            <a
              href="/"
              className="text-base leading-6 text-white hover:text-orange/100 mb-3"
            >
              <Img
                src="images/talentsV2.png"
                alt="play_one"
                className="h-[250px] w-[250px] sm:w-[200px] sm:h-[200px]"
              />
            </a>

            <div className="flex antialiased space-x-2">
              <div className="px-3 py-2">
                <a
                  href="/hired"
                  className="text-[18px] font-medium leading-6 text-white hover:text-orange/100"
                >
                  Get Hired
                </a>
              </div>
              <div className="px-3 py-2">
                <a
                  href="/find"
                  className="text-[18px] font-medium leading-6 text-white hover:text-orange/100"
                >
                  Find Talent
                </a>
              </div>
              <div className="px-3 py-2">
                <a
                  href="/other"
                  className="text-[18px] font-medium leading-6 text-white hover:text-orange/100"
                >
                  Other
                </a>
              </div>
            </div>
          </nav>
        </div>
      </section>
    </div>
  );
}

const CardComponent = ({ cards }) => {
  return (
    <div className="relative">
      {cards.map((card, index) => (
        <Card key={index} card={card} index={index} />
      ))}
    </div>
  );
};

const Card = ({ card, index }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <div
      ref={ref}
      className={`relative mb-[5%] sm:mb-[30%] md:h-[360px] h-[280px] rounded-lg shadow transition-all mx-2 lg:mx-4 transform ${
        inView
          ? `opacity-100 translate-y-0 transition-all duration-700 ease-out`
          : `opacity-0 translate-y-12 transition-all duration-700 ease-in-out`
      }`}
      style={{
        willChange: "transform, opacity",
      }}
    >
      <div className="flex justify-center p-2 items-center">
        <div className="max-w-[720px] mx-auto">
          <div className="relative flex bg-clip-border rounded-xl shadow-md w-full max-w-[48rem] flex-row">
            
             { index === 2 && (
              
            <div
            key={index}
            ref={card.ref}
            className={`relative w-[800px] overflow-hidden md:h-[360px] h-[280px] rounded-lg shadow transition hover:shadow-lg mx-2 lg:mx-4 ${
              card.inView
                ? `transform translate-y-0 opacity-100 transition-all duration-1000 ease-out delay-[${index * 300}ms]`
                : "transform translate-y-10 opacity-0"
            }`}
          >
           
            <div className="relative pt-20 sm:pt-20 lg:pt-64">
              <div className="p-4 sm:p-6">
            
                <a href="#">
                  <h3 className="text-2xl text-white font-inter">Join Us Now</h3>
                </a>
              
                <div className="mt-[20px] flex items-center justify-center gap-4">
                  <a
                    href="/hired"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center"
                  >
                    <button className="relative font-inter inline-flex h-10 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">
                      <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#fff_0%,#ff4201_50%,#ff4201_100%)]" />
                      <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-6 py-1 text-sm font-medium text-white backdrop-blur-3xl">
                        Get Hired
                      </span>
                    </button>
                  </a>

                  <a
                    href="/find"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center"
                  >
                    <button className="relative  font-inter inline-flex h-10 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">
                      <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_0deg_at_50%_50%,#fff_0%,#ff4201_50%,#ff4201_100%)]" />
                      <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-6 py-1 text-sm font-medium text-white backdrop-blur-3xl">
                        Find Talent
                      </span>
                    </button>
                  </a>
                </div>
              </div>
          
          
              </div>
                </div>
             )}

                { index !== 2 && (

            <div className="p-6">
              <h4 className="block mb-2 font-sans text-3xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                {card.title}
              </h4>
              <p className="block mb-8 font-sans text-xl antialiased font-normal leading-relaxed text-white">
                {card.description}
              </p>
              <a href="#" className="inline-block">
               { index === 0 && (
               
                 <button className="relative font-inter inline-flex h-10 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">
                      <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_0deg_at_50%_50%,#fff_0%,#ff4201_50%,#ff4201_100%)]" />
                      <span className="inline-flex h-full w-[160px] cursor-pointer items-center justify-center rounded-full bg-slate-950 px-6 py-1 text-sm font-medium text-white backdrop-blur-3xl">
                        <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    className="w-4 h-4 mr-[3%]"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                    ></path>
                  </svg>
                       Learn More
                       
                      </span>
                      
                    </button>

        
               )}
              </a>
            </div>
               )}
          </div>

            { index !== 2 && (
           <div className="font-inter fade-in-up items-center md:mt-[20%] text-white flex flex-col justify-center text-3xl w-full">
             
              <div className="w-4 h-4 mt-[5%] md:mt-[0%] md:hidden rounded-full bg-orange"></div>
            </div>
            )}
        </div>
        
      </div>
      
    </div>
  );
};

export default function Landing() {
  const { ref: fadeInUpRef, inView: fadeInUpInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const { ref: partnersRef, inView: partnersInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const { ref: learnMoreRef, inView: learnMoreInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const { ref: card1Ref, inView: card1InView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const { ref: card2Ref, inView: card2InView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const { ref: card3Ref, inView: card3InView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const about = [
    {
      ref: card1Ref,
      inView: card1InView,
      title: "About Our Recruiting Agency",
      description:
        "We bridge the gap between top community talent and Solana ecosystem projects looking to scale. Whether you’re a company looking to grow your team or a community member looking for a new challenge, we’re here to make the connection and hiring process seamless.",
      image: "./images/Superteam Germany GWJRjanWcAEXg9X.jpeg",
      date: "2022-10-10",
      time: "About Us",
    },
  ];

  const cards = [
    {
      ref: card1Ref,
      inView: card1InView,
      title: "The story behind Talents",
      description:
        "Our long-standing presence in the Solana ecosystem has played a vital role in its growth. Companies often face the hurdle of finding talent who’s familiar with Solana and deeply involved in the community.",
      image: "./images/Superteam Germany GWJRjanWcAEXg9X.jpeg",
      date: "2022-10-10",
      time: "The Origin",
    },
    {
      ref: card2Ref,
      inView: card2InView,
      title: "About Our Agency",
      description:
        "We bridge the gap between top community talent and Solana ecosystem projects looking to scale. Whether you’re a company looking to grow your team or a community member looking for a new challenge.",
      image: "./images/w3.jpeg",
      date: "2022-10-10",
      time: "Why Talents",
    },
    {
      ref: card3Ref,
      inView: card3InView,
      title: "Test Title",
      description:
        "Our long-standing presence in the Solana ecosystem has played a vital role in its growth. Companies often face the hurdle of finding talent who’s familiar with Solana and deeply involved in the community.",
      image: "./images/w3.jpeg",
      date: "2022-10-10",
      time: "Test Sub",
    },
  ];

  return (
    <>
      <div className="relative overflow-hidden inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:14px_24px] min-h-[50vh] md:min-h-[70vh]">
        <div className="" />
        <div className="bg-black-100">
          <Navigation />

          <div className="mt-[12%] font-inter relative">
            <div className="absolute flex flex-col items-center justify-center inset-0">
              <img
                src={"/images/ball.png"}
                alt="spinning globe"
                className="w-[420px] h-[420px] animate-spinGlobe object-cover opacity-30"
              />
            </div>

            <div className="relative z-10 pt-[10px] fade-in-up">
              <div className="mx-auto flex max-w-5xl flex-col items-center">
                <h2 className="text-center text-4xl font-medium text-white sm:text-3xl">
                  EMPOWERING SOLANA'S ECOSYSTEM WITH TALENT
                  <span className="animate-text-gradient inline-flex bg-gradient-to-r from-white via-slate-300 to-neutral-100 bg-[200%_auto] bg-clip-text leading-tight text-transparent dark:from-neutral-100 dark:via-slate-400 dark:to-neutral-400"></span>
                </h2>
                <p className="mt-2 text-center text-base sm:text-sm md:text-[10px] px-4 sm:px-7 lg:px-8 leading-6 text-gray-200">
                  CONNECTING COMMUNITY MEMBERS WITH LEADING ECOSYSTEM PROJECTS
                </p>

                <div className="mt-[40px] flex gap-4">
                  <a
                    href="/hired"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center"
                  >
                    <button className="relative inline-flex h-10 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">
                      <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#fff_0%,#ff4201_50%,#ff4201_100%)]" />
                      <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-6 py-1 text-sm font-medium text-white backdrop-blur-3xl">
                        Get Hired
                      </span>
                    </button>
                  </a>

                  <a
                    href="/find"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center"
                  >
                    <button className="relative inline-flex h-10 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">
                      <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_0deg_at_50%_50%,#fff_0%,#ff4201_50%,#ff4201_100%)]" />
                      <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-6 py-1 text-sm font-medium text-white backdrop-blur-3xl">
                        Find Talent
                      </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            ref={partnersRef}
            className={`mt-[80px] px-6 sm:px-10 transition-opacity duration-1000 ease-out ${
              partnersInView ? "opacity-100" : "opacity-0 translate-y-10"
            }`}
          >
            <div className="w-full">
              <Partners />
            </div>
          </div>

          <div className="text-center text-4xl font-medium text-white sm:text-3xl transition-transform duration-1000 ease-out">
            <div className="font-inter fade-in-up mt-[10%] items-center md:mt-[20%] text-white flex flex-col justify-center text-3xl w-full"/>
             
            <div className="font-inter grid grid-rows-2 md:grid-cols-1 w-[45%] md:w-[100%] p-10 text-center justify-items-center mx-auto mb-[-5%] lg:mb-40"></div>

            <CardComponent cards={cards}></CardComponent>
          </div>

          <div className="w-full">
            <AppFooter />
          </div>
        </div>
      </div>
    </>
  );
}
