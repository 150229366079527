"use client";
import { useWindowSize } from "@react-hook/window-size";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useRafLoop } from "react-use";
import { twMerge } from "tailwind-merge";

const MarqueeItem = ({ children, speed }) => {
  const itemRef = useRef(null);
  const rectRef = useRef(null);
  const [x, setX] = useState(0);
  const [width, height] = useWindowSize();

  useEffect(() => {
    if (itemRef.current) {
      rectRef.current = itemRef.current.getBoundingClientRect();
    }
  }, [width, height]);

  const loop = () => {
    if (!rectRef.current || !itemRef.current) {
      return;
    }
    const newX = x - speed;

    const xPercentage = (newX / rectRef.current.width) * 100;
    if (xPercentage < -100) {
      setX(0);
    } else if (xPercentage > 0) {
      setX(-rectRef.current.width);
    } else {
      setX(newX);
    }
    itemRef.current.style.transform = `translate3d(${xPercentage}%, 0, 0)`;
  };

  const [_, loopStart] = useRafLoop(loop, false);

  useEffect(() => {
    loopStart();
  }, []);

  return (
    <motion.div ref={itemRef} style={{}}>
      {children}
    </motion.div>
  );
};

export const Marquee = ({ children, speed }) => {
  return (
    <div className="w-full whitespace-nowrap overflow-hidden">
      <motion.div className={twMerge("flex flex-row")}>
        <MarqueeItem speed={speed ?? 1}>{children}</MarqueeItem>
        <MarqueeItem speed={speed ?? 1}>{children}</MarqueeItem>
      </motion.div>
    </div>
  );
};