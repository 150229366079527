import React from "react";

export function AppFooter() {
  const year = new Date().getFullYear();

  return (
    <div>
      <section className="bg-black font-inter">
        <div className="w-[100%] p-5 mx-auto overflow-hidden sm:px-6 lg:px-8">
          <div className="flex flex-row sm:flex-col items-center justify-between sm:space-y-0">
            <p className="text-base text-white">
              © {year} Talents. All rights reserved.
            </p>

            <nav className="flex space-x-8 mx-auto sm:mx-0">
              <div className="py-2">
                <a
                  href="#"
                  className="text-base leading-6 text-white hover:text-orange"
                >
                  About
                </a>
              </div>
              <div className="py-2">
                <a
                  href="#"
                  className="text-base leading-6 text-white hover:text-orange"
                >
                  Policy
                </a>
              </div>
              <div className="py-2">
                <a
                  href="#"
                  className="text-base leading-6 text-white hover:text-orange"
                >
                  Terms
                </a>
              </div>
            </nav>

        <div className="flex space-x-4 ml-auto">
              <a href="#" className="text-white hover:text-orange">
                <span className="sr-only">Instagram</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-telegram"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09" />
                </svg>
              </a>
              <a
                href="https://x.com/talents_build"
                className="text-white hover:text-orange"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  version="1.2"
                  fill="currentColor"
                  viewBox="0 0 300 271"
                >
                  <path
                    fillRule="evenodd"
                    d="M236 0h46L181 115l118 156h-92.6l-72.5-94.8-83 94.8h-46l107-123L-1.1 0h94.9l65.5 86.6zm-16.1 244h25.5L80.4 26H53z"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
