import React from "react";
import { useRoutes } from "react-router-dom";
import NotFound from "pages/NotFound";
import Landing from "pages/landing";
import Hired from "pages/hired";
import Find from "pages/find";
import Other from "pages/other";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <Landing /> },
    { path: "*", element: <NotFound /> },
    {
      path: "hired",
      element: <Hired />,
    },
     {
      path: "find",
      element: <Find />,
    },
    
     {
      path: "other",
      element: <Other />,
    },
  ]);

  return element;
};

export default ProjectRoutes;
