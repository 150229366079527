import React, { useState } from "react";
import { Img } from "../../components";
import { AppFooter } from "components/Footer";

export function Navigation() {
  return (
    <div>
      <section className="p-5 mt-[-3%] bg-black font-inter">
        <div className="max-w-screen-xl px-4 mx-auto overflow-hiddensm:px-6 lg:px-8">
          <nav className="flex justify-between sm:flex-col items-center">
            <a
              href="/"
              className="text-base leading-6 text-white hover:text-orange/100 mb-3"
            >
              <Img
                src="images/talentsV2.png"
                alt="play_one"
                className="h-[250px] w-[250px] sm:w-[200px] sm:h-[200px]"
              />
            </a>

            <div className="flex antialiased space-x-2">
              <div className="px-3 py-2">
                <a
                  href="/hired"
                  className="text-[18px] font-medium leading-6 text-white hover:text-orange/100"
                >
                  Get Hired
                </a>
              </div>
              <div className="px-3 py-2">
                <a
                  href="/find"
                  className="text-[18px] font-medium leading-6 text-white hover:text-orange/100"
                >
                  Find Talent
                </a>
              </div>
              <div className="px-3 py-2">
                <a
                  href="/other"
                  className="text-[18px] font-medium leading-6 text-white hover:text-orange/100"
                >
                  Other
                </a>
              </div>
            </div>
          </nav>
        </div>
      </section>
    </div>
  );
}

export default function Other() {
  return (
    <>
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:14px_24px] min-h-[50vh] md:min-h-[70vh]">
        <div className="bg-black-100">
          <Navigation />

          <div className="font-inter flex flex-col items-center">
            <section className="text-white flex flex-col text-center">
              <div className="max-w-screen-xl flex flex-col items-center px-4 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                <h2 className="text-5xl md:w-[400px] font-bold sm:text-4xl">
                  About Us
                </h2>
                <p className="mt-[5%] text-base md:w-[400px] text-white text-center w-[700px]">
                  As an embedded community member or Solana enthusiast, you’re
                  already part of something special. Now, let us help you grow
                  and develop by connecting you with exciting job opportunities
                  in the ecosystem. Join our talent pool and gain access to
                  exclusive roles with leading companies focused on Solana’s
                  future.
                </p>
                <div className="w-4 h-4 mt-[10%] rounded-full bg-orange mt-2"></div>
              </div>
            </section>

            <div className="py-1 mb-[5%]">
              <div className="container max-w-screen-md mx-auto px-6 space-y-8 text-white">
                <div className="mt-16 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                  <div className="p-6 rounded-lg shadow-md">
                    <p className="font-medium text-orange">Contact us</p>
                    <h1 className="mt-2 text-2xl font-semibold text-white md:text-3xl">
                      Get in touch with us
                    </h1>
                  </div>

                  <div className="p-6 rounded-lg shadow-md">
                    <span className="inline-block p-3 text-orange rounded-full bg-orange/20 dark:bg-gray-800">
                      <svg
                        class="w-6 h-6"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                      >
                        <path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z" />
                      </svg>
                    </span>
                    <h2 className="mt-4 text-lg font-medium text-white dark:text-white">
                      Email
                    </h2>

                    <p className="mt-2 text-white">team@talents.build</p>
                  </div>

                  <div className="p-6 rounded-lg shadow-md">
                    <span className="inline-block p-3 text-orange rounded-full bg-orange/20 dark:bg-gray-800">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                        />
                      </svg>
                    </span>
                    <h2 className="mt-4 text-lg font-medium text-white dark:text-white">
                      Phone
                    </h2>
                    <p className="mt-2 text-white">+49 152 02606471</p>
                  </div>

                  <div className="p-6 rounded-lg shadow-md">
                    <span className="inline-block p-3 text-orange rounded-full bg-orange/20 dark:bg-gray-800">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        version="1.2"
                        fill="currentColor"
                        viewBox="0 0 300 271"
                      >
                        <path
                          fillRule="evenodd"
                          d="M236 0h46L181 115l118 156h-92.6l-72.5-94.8-83 94.8h-46l107-123L-1.1 0h94.9l65.5 86.6zm-16.1 244h25.5L80.4 26H53z"
                        ></path>
                      </svg>
                    </span>
                    <h2 className="mt-4 text-lg font-medium text-white dark:text-white">
                      Twitter
                    </h2>

                    <p className="mt-2 text-white">
                      https://x.com/talents_build
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full">
              <AppFooter />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
